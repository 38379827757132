import React from 'react';
import {useLanguageChanger, langMap} from '../../modules/person/hooks';
import Footer from './footer';
import Link from 'next/link';
import {useTranslation} from '../../modules/i18n/use-translation';
import {getPromoUrl} from '../../modules/util/get-promo-url';

const FooterController: React.FC = function FooterController() {
    const [lang, changeLang] = useLanguageChanger();
    const {t} = useTranslation();
    const handleChangeLanguage = React.useCallback(
        function handleChangeLanguage(e: React.SyntheticEvent) {
            e.preventDefault();

            changeLang(langMap[lang]);
        },
        // We don't want to listen to changes on changeLang function, it's unnecessary

        [lang]
    );

    const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;
    const PROJECT_NAME = `${process.env.NEXT_PUBLIC_PROJECT_NAME}`;
    const footerLeftLinks = [];
    const footerRightLinks = [];
    const aboutText = PROJECT_NAME === 'fonoma' ? 'who-we-are' : 'about-teloa';
    const rechargeText =
        PROJECT_NAME === 'fonoma' ? 'cellular-recharge' : 'cellphone-refills';
    const rechargeLink =
        PROJECT_NAME === 'fonoma'
            ? `${SERVER_BASE_URL}/phone`
            : SERVER_BASE_URL;
    const helpDeskURL =
        PROJECT_NAME === 'fonoma' ? 'help-desk-url' : 'teloa-help-desk-url';
    const blog = (
            <a target="_self" href={process.env.NEXT_PUBLIC_BLOG_URL}>
                {t('footer:blog')}
            </a>
        );

    footerLeftLinks.push(
        <Link href={`${SERVER_BASE_URL}/about`}>
            {t(`footer:${aboutText}`)}
        </Link>
    );
    footerLeftLinks.push(blog);
    footerLeftLinks.push(
        <a target="_self" href={t(`footer:${helpDeskURL}`)}>
            {t('footer:help')}
        </a>
    );
    const promoUrl = getPromoUrl(lang);
    footerRightLinks.push(
        <a target="_self" href={promoUrl || undefined}>
            {t('footer:promotions')}
        </a>
    );

    footerRightLinks.push(
        <a target="_self" href={rechargeLink}>
            {t(`footer:${rechargeText}`)}
        </a>
    );
    footerRightLinks.push(
        <a target="_self" href={`${SERVER_BASE_URL}/invitation`}>
            {t('footer:invite-your-friends')}
        </a>
    );

    return (
        <Footer
            handleChangeLanguage={handleChangeLanguage}
            leftLinks={footerLeftLinks}
            rightLinks={footerRightLinks}
        />
    );
};

export default FooterController;
