import styled from 'styled-components';
import {mediaQueries} from '../modules/common';

/**
 * Styled component for our pages content.
 *
 * Intended to be used like this
 *
 * ````javascript
 * const AboutPage: NextPage<Props> = ({t: trans}) => (
    <>
        <AboutGlobalStyle/>
        <Head>
            <title>{trans('title')}</title>
        </Head>
        <NavBar/>
        <MainContentContainer>
            ... # Add content here
        </MainContentContainer>
    </>
);
 * ````
 */
const MainContentContainer = styled.div`
    padding: 0;
    margin: 0 auto;
    ${mediaQueries.lessThan('small')`
    width: 100%;
  `};
`;

export default MainContentContainer;
