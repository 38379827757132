import {mediaQueries} from "../../modules/common/styles";
import styled from 'styled-components';

const Grain = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    ${({theme}) => theme.grain.page};
`;

const Gradients = styled.div`
    width: 100%;
    height: 100%;
    content: '';
    ${({theme}) => theme.gradients.notFound};
`;


const AboutGradient = styled.div`
    height: 100%;
    width: 100%;
    ${({theme}) => theme.gradients.about};
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;

    ${mediaQueries.lessThan('small')`
        display: none;
    `}
`;

export enum GradientType {
    About,
    NotFound,
}

export interface GradientPickerProps {
    gradient: GradientType;
}

const GradientPicker = ({gradient}: GradientPickerProps): JSX.Element => {
    switch (gradient) {
        case GradientType.About:
            return <AboutGradient />;
        case GradientType.NotFound:
            return <Gradients />;
    }
};


export const GradientBackground = ({gradient}: GradientPickerProps): JSX.Element => {
    return (
        <Background className="background">
            <GradientPicker gradient={gradient}></GradientPicker>
            <Grain></Grain>
        </Background>
    );
};
