import MainContentContainer from '../main-content-container';
import styled from 'styled-components';
import {mediaQueries} from '../../modules/common';

/**
 * Component that contains the middle part of the terms, privacy and about pages
 */
const CommonContentContainer = styled(MainContentContainer)`
    background: ${(props): string => props.theme.colors.arroz};
    border: solid ${(props): string => props.theme.colors.atun};
    border-width: 0 1px;
    color: #4f4f4f;
    ${mediaQueries.lessThan('small')`
    padding: 0;
  `}
`;

export default CommonContentContainer;
