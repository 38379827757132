import {LANGUAGES, LOCALES} from '../util/constants';

export const getAfterLanguageChangeURL = (currentURL: string): string => {
    const _currentURL = new URL(currentURL);
    if (_currentURL.host.startsWith(LANGUAGES.ENGLISH)) {
        const newURL = new URL(
            _currentURL.pathname,
            process.env.NEXT_PUBLIC_SERVER_BASE_URL
        );
        return newURL.toString();
    }

    const languagePaths = [`/${LOCALES.ENGLISH}`, `/${LOCALES.SPANISH}`];

    const matchingLocalePath = languagePaths.find((path) => {
        if (_currentURL.pathname.toLowerCase().startsWith(path.toLowerCase())) {
            return path;
        }
        return undefined;
    });

    if (matchingLocalePath) {
        const newURL = new URL(
            _currentURL.pathname.slice(matchingLocalePath.length),
            process.env.NEXT_PUBLIC_SERVER_BASE_URL
        );
        return newURL.toString();
    }

    return currentURL;
};
