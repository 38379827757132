import React from 'react';
import styled from 'styled-components';
import {Logo} from './logo';
import {StyledProps, mediaQueries} from '../modules/common';

const NavBarContentContainer = styled.div`
    border: none;
    padding: 0;
`;

/**
 * Base component for all navbar logic
 */
const BaseNavBar = (props: StyledProps): JSX.Element => {
    const {className} = props;
    return (
        <div className={className}>
            <NavBarContentContainer>
                <Logo />
            </NavBarContentContainer>
        </div>
    );
};

/**
 * Styled navbar
 */
const NavBar = styled(BaseNavBar)`
    display: flex;
    align-items: center;
    background-color: ${(props): string => props.theme.navBar.backgroundColor};
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    min-height: ${(props): string =>
        `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};
    ${mediaQueries.lessThan('small')`
        min-height: ${(props): string =>
            `${props.theme.mobileMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};
  `};
`;

export default NavBar;
