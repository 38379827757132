import {createGlobalStyle} from 'styled-components';

/**
 * Global styles that will be applied to the about page
 */
const AboutGlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props): string => props.theme.bodyBackgroundColor};
    padding-top: 0px;
  }
`;

export default AboutGlobalStyle;
