/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import {StyledProps} from '../modules/common';

export interface Props extends StyledProps {
    imageUrl: string;
    alt: string;
    href?: string;
    internalLink?: boolean;
    onClick?: () => void;
}

/**
 * Small component that renders an image inside an <a>.
 * If href is passed, it renders a link pointing to href.
 * The param internalLink is only used in conjunction with href, it indicates
 * if the link is internal to our site or not. If internalLink is true, it will
 * render <Link> element (for prefetching).
 *
 * If onClick is passed, it will be used as the event handler for the click event
 * on the component.
 *
 * Params href and onClick are mutually exclusive (if both are present, href takes precedence).
 */
const UnstyledClickableImage = (props: Props): JSX.Element => {
    const {className, imageUrl, alt, href, internalLink, onClick} = props;
    if (href !== undefined) {
        const _internalLink = internalLink === undefined ? true : internalLink;
        if (_internalLink) {
            return (
                <Link href={href} className={className}>
                    <img alt={alt} src={imageUrl} />
                </Link>
            );
        }
        return (
            <a className={className} href={href}>
                <img alt={alt} src={imageUrl} />
            </a>
        );
    } else if (onClick !== undefined) {
        return (
            <a
                className={className}
                onClick={(): void => {
                    onClick();
                }}
                href="#"
            >
                <img alt={alt} src={imageUrl} />
            </a>
        );
    } else {
        throw new Error('href or onClick are required');
    }
};

const ClickableImage = styled(UnstyledClickableImage)`
    line-height: 0;
    > img {
        padding: 0;
        margin-top: 0;
    }
`;

export {UnstyledClickableImage, ClickableImage};
