import React from 'react';
import styled from 'styled-components';
import {ClickableImage} from './clickable-image';
import {StyledProps, mediaQueries} from '../modules/common';

/**
 * Markup for fonoma's base logo
 */
const UnstyledBaseLogo = (props: StyledProps): JSX.Element => {
    const {className} = props;
    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;

    return (
        <ClickableImage
            className={className}
            href="/"
            internalLink={false} 
            imageUrl={`${process.env.NEXT_PUBLIC_ASSETS_URL}/v2/web_logo_desktop.svg`}
            alt={`${PROJECT_NAME} Logo`}
        />
    );
};

/**
 * Component that renders a clickable fonoma's logo without borders
 */
const BaseLogo = styled(UnstyledBaseLogo)`
    float: left;
    > img {
        height: ${(props): number => props.theme.logo.desktop.height}px;

        ${mediaQueries.lessThan('small')`
            height: ${(props): number => props.theme.logo.mobile.height}px;
        `};
    }
`;

/**
 * Markup for fonoma's logo inside a div
 */
const UnstyledLogo = (props: StyledProps): JSX.Element => {
    const {className} = props;
    return (
        <div className={className}>
            <BaseLogo />
        </div>
    );
};

/**
 * Renders a clickable fonoma's logo with some space around
 */
const Logo = styled(UnstyledLogo)`
    margin: 0;
    padding: 0 24px;
    float: left;
    ${mediaQueries.lessThan('small')`
        padding: 0 16px;
  `};
`;

export {Logo, BaseLogo};
