import React from 'react';
import styled from 'styled-components';
import {StyledProps} from '../modules/common';
import Link from 'next/link';

interface Props extends StyledProps {
    href?: string;
    internalLink?: boolean;
    children: React.ReactNode;
}

/**
 * Small component that renders an anchor. Intended to be styled by another component.
 */
const UnstyledAnchor = (props: Props): JSX.Element => {
    const {href, children, className, internalLink} = props;
    if (internalLink && href) {
        return (
            <Link href={href} className={className}>
                {children}
            </Link>
        );
    } else {
        if (href) {
            return (
                <a className={className} href={href}>
                    {children}
                </a>
            );
        } else {
            return (
                <a className={className} href="#">
                    {children}
                </a>
            );
        }
    }
};

interface ColoredLinkStyledProps extends Props {
    fontColor?: string;
}

/**
 * Styled colored link
 */
const ColoredLink = styled(UnstyledAnchor)<ColoredLinkStyledProps>`
    color: ${(props): string =>
        props.fontColor !== undefined ? props.fontColor : 'inherit'}!important;
`;

export {UnstyledAnchor};
export default ColoredLink;
