import React from 'react';
import styled from 'styled-components';

const AppLink = styled.a`
    display: inline-block;
    outline: 0;
    color: transparent;
    text-decoration: none;
    background-color: transparent;
`;

const AppLinkImage = styled.img`
    vertical-align: middle;
    box-sizing: border-box;
`;
interface AppBadgeProps {
    lang: string;
    url: string;
    className?: string;
}

export const GooglePlayBadge = (props: AppBadgeProps): JSX.Element => (
    <div className={props.className}>
        <AppLink href={props.url}>
            <AppLinkImage
                src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/badges_bottoms/v2/Google_Play_Badges_${props.lang.toUpperCase()}.svg`}
            />
        </AppLink>
    </div>
);

export const AppStoreBadge = (props: AppBadgeProps): JSX.Element => (
    <div className={props.className}>
        <AppLink href={props.url}>
            <AppLinkImage
                src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/badges_bottoms/v2/App_Store_Badges_${props.lang.toUpperCase()}.svg`}
            />
        </AppLink>
    </div>
);
