import React from 'react';

import {usePerson} from './context';
import {useTranslation} from '../i18n/use-translation';
import {changeLanguage} from '../i18n/i18n';
import {personResource} from './person-resource';
import {useTrack} from '../analytics/use-track';
import {useRollbar} from '@rollbar/react';
export const langMap = {
    en: 'es',
    es: 'en',
};
import {useGlobalLoadingIndicator} from '../global-loading-indicator/context';

export function useLanguageChanger(): [
    string,
    React.Dispatch<React.SetStateAction<string>>
] {
    // get the relevant person state and dispatch fn
    const [personState, dispatch] = usePerson();

    const {showLoadingIndicator} = useGlobalLoadingIndicator();

    const trackFn = useTrack();
    
    const rollbar = useRollbar();

    // determine current lang.
    const {lang} = useTranslation();

    const {isLoggedIn, person} = personState;
    const id = person?.id;

    // init local state
    const [currentLang, changeLang] = React.useState(lang);

    // watch 'currentLang' for changes
    React.useEffect(() => {
        async function updatePersonLang(): Promise<void> {
            showLoadingIndicator();
            dispatch({type: 'person/CHANGE_LANG_REQUEST'});
            if (isLoggedIn && person.lang !== currentLang) {
                try {
                    await personResource.update<{lang: string}>(id, {
                        lang: currentLang,
                    });
                } catch (e) {
                    
                    rollbar.error(
                        'Person update after language change failed',
                        
                        e
                    );
                    // If an error happens while changing the language, let's not break the flow.
                }
            }

            
            await changeLanguage(trackFn, langMap[currentLang], currentLang);
            dispatch({
                type: 'person/CHANGE_LANG_SUCCESS',
                lang: currentLang,
            });
        }

        if (currentLang !== lang) {
            void updatePersonLang();
        }
        // We just want to listen to changes on
        
    }, [currentLang, dispatch]);

    return [currentLang, changeLang];
}
