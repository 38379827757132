import styled from 'styled-components';
import React from 'react';
import {useTranslation} from '../../modules/i18n/use-translation';
import Link from 'next/link';
import {StyledProps, mediaQueries} from '../../modules/common';
import ColoredLink from '../colored-link';
import Centered from '../centered';
import {
    FONOMA_ENVS,
    INSTRUCTIONS_LINK,
} from '../../modules/util/constants';
import {AppStoreBadge, GooglePlayBadge} from './mobile-app-badge';
import {InstagramIcon} from '../common/icons/instagram';
import {FacebookIcon} from '../common/icons/facebook';
import {XIcon} from '../common/icons/x';
import {YouTubeIcon} from '../common/icons/youtube';
import {SvgIcon} from '../common/icons/svg-icon';
import {
    typographyTitleBodyStyles,
    typographyMediumBodyStyles,
    typographyBodyStyles,
} from '../typography.styles';
import {TiktokIcon} from '../common/icons/tiktok';

/**
 * Internal styled component. Used to render the copyright line.
 */
const CopyrightContainer = styled(Centered)`
    display: flex;
    justify-content: space-between;
    ${typographyMediumBodyStyles}
    margin: 0;
    padding: 48px 0 0;

    ${mediaQueries.lessThan('medium')`
        padding: 24px 0 0;
    `};

    a {
        color: ${(props): string => props.theme.colors.ballena};
        cursor: pointer;
        padding-left: 24px;

        ${mediaQueries.lessThan('medium')`
            padding-left: 0;
            padding-right: 24px;
        `};
    }

    .links-container {
        display: flex;

        ${mediaQueries.lessThan('medium')`
            align-items: start;
            flex-direction: column-reverse;
        `};
    }

    .links {
        ${mediaQueries.lessThan('medium')`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 16px 0;
        `};
    }

    ${mediaQueries.lessThan('medium')`
        align-items: start;
        flex-direction: column-reverse;
    `};
`;

const FooterAppStoreBadge = styled(AppStoreBadge)`
    display: inline-block;
`;

const FooterGooglePlayBadge = styled(GooglePlayBadge)`
    display: inline-block;
    margin-left: 16px;
`;

const SocialLink = styled.a`
    color: ${(props): string => props.theme.colors.ballena};
    text-decoration: none;
    user-select: none;
    margin-left: 28px;
    font-size: 20px;
    display: inline-block;
    line-height: 1.2rem;
    &:hover,
    &:active,
    &:visited {
        transition: none;
        text-decoration: none;
        color: ${(props): string => props.theme.colors.ballena};
    }
    &:first-child {
        margin-left: 0;
    }
`;

interface FooterProps extends StyledProps {
    handleChangeLanguage: (e: React.SyntheticEvent) => void;
    rightLinks: React.ReactNode[];
    leftLinks: React.ReactNode[];
}

/**
 * Markup component for fonoma's logo
 *
 */
const UnstyledFooter = (props: FooterProps): JSX.Element => {
    const {t, lang} = useTranslation('footer');
    const {className, handleChangeLanguage, rightLinks, leftLinks} = props;

    const isDev =
        process.env.NEXT_PUBLIC_PROJECT_ENV !== FONOMA_ENVS.PRODUCTION;
    const appLink = process.env.NEXT_PUBLIC_MOBILE_APP_URL;
    const PROJECT_DISPLAY_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;
    const START_YEAR = (process.env.NEXT_PUBLIC_PROJECT_NAME as string) === 'fonoma' ? '2014' : '2023';
    const emailFooterText =
        (process.env.NEXT_PUBLIC_PROJECT_NAME as string) === 'retope' ? (
            <Link href="mailto:help@teloa.com">
                help@teloa.com
            </Link>
        ) : (
            ''
        );

    let _conditionalInstructions;
    if (isDev) {
        _conditionalInstructions = (
            <li data-testid="instructions-link">
                <ColoredLink fontColor="red" href={INSTRUCTIONS_LINK}>
                    {t('instructions')}
                </ColoredLink>
            </li>
        );
    } else {
        _conditionalInstructions = null;
    }

    const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;

    return (
        <footer className={className} data-testid="the-footer">
            <div className="content">
                <section>
                    <div className="links-list">
                        <ul>
                            {leftLinks?.map((item: React.ReactNode, i) => {
                                    return (<li key={i}>{item}</li>);
                            })}
                        </ul>
                    </div>
                    <div className="links-list">
                        <ul>
                            {rightLinks?.map((item: React.ReactNode, i) => {
                                    return (<li key={i}>{item}</li>);
                            })}
                            {_conditionalInstructions}
                        </ul>
                    </div>
                </section>
                <div className="social-section">
                    <div className="apps-row">
                        <FooterAppStoreBadge lang={lang} url={appLink} />
                        <FooterGooglePlayBadge lang={lang} url={appLink} />
                    </div>
                    <div className="social-media-row">
                        <SocialLink href={process.env.NEXT_PUBLIC_TWITTER_URL}>
                            <XIcon />
                        </SocialLink>
                        <SocialLink href={process.env.NEXT_PUBLIC_FACEBOOK_URL}>
                            <FacebookIcon />
                        </SocialLink>
                        <SocialLink href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}>
                            <InstagramIcon />
                        </SocialLink>
                        <SocialLink href={process.env.NEXT_PUBLIC_YOUTUBE_URL}>
                            <YouTubeIcon />
                        </SocialLink>
                        <SocialLink href={process.env.NEXT_PUBLIC_TIKTOK_URL}>
                            <TiktokIcon />
                        </SocialLink>
                    </div>
                </div>
            </div>
            <CopyrightContainer>
                <div className="links-container">
                    {t('footer:copyright', {
                        project_display_name: PROJECT_DISPLAY_NAME,
                        startYear: START_YEAR,
                        year: new Date().getFullYear(),
                    })}
                    <div className="links">
                        <Link href={`${SERVER_BASE_URL}/terms`}>{t('footer:terms-of-use')}</Link>
                        <Link href={`${SERVER_BASE_URL}/privacy`}>
                            {t('footer:privacy-policy')}
                        </Link>
                        {emailFooterText}
                    </div>
                </div>
                <a
                    data-testid="change-language-button"
                    onClick={handleChangeLanguage}
                >
                    {t('footer:alternative-language')}
                </a>
            </CopyrightContainer>
        </footer>
    );
};

/**
 * Styled component that renders fonoma's footer
 */
const Footer = styled(UnstyledFooter)`
    position: relative;
    padding: 64px calc((100vw - 960px) / 2) 24px;
    ${SvgIcon} {
        font-size: 20px;
    }
    background: ${(props): string => props.theme.colors.caballa};
    text-align: center;
    ${mediaQueries.lessThan('medium')`
        padding: 40px 16px 24px;
    `};

    .content {
        display: flex;
        justify-content: space-between;
        padding: 0 0 8px 0;
        border-bottom: 1px solid ${(props): string => props.theme.colors.atun};
        margin: 0;

        ${mediaQueries.lessThan('medium')`
            flex-direction: column;
            padding-bottom: 24px;
        `};
    }

    section {
        display: flex;
        flex-grow: 2;

        ${mediaQueries.lessThan('medium')`
        flex-direction: column;
        padding-bottom: 12px;
        `};
    }

    .social-section {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: end;

        .apps-row {
            padding-bottom: 24px;
        }

        ${mediaQueries.lessThan('medium')`
            align-items: start;
        `};
    }

    .links-list {
        vertical-align: top;
        text-align: left;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;

        ${mediaQueries.lessThan('medium')`
            padding-bottom: 16px;
        `};

        > span {
            ${typographyTitleBodyStyles}
            padding-bottom: 16px;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                padding-bottom: 16px;
                list-style: none;

                a {
                    text-decoration: none;
                    ${typographyBodyStyles}
                }
            }
        }
    }

    a {
        text-decoration: none;
    }
`;

export default Footer;
